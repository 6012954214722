<template>
  <div class="inputs">
    <h1>iBot Admin</h1>
    <p>(version 0.7)</p>
    <p>
      Here you can add new users, upload production or sandbox code and soon,
      reset user passwords.
    </p>
  </div>
</template>
<script>
export default {
  computed: {
    localauth() {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    if (!this.localauth) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.about {
  border-radius: 0.25rem;
  border: 1px solid#464943;
  box-shadow: brown;
  display: block;
  margin: auto;
  margin-top: 4rem;
  width: 20rem;
  min-width: 20rem;
  padding: 0.5rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style>
